<template>
    <div class="project_logo_box" id="toEcosytem">
        <div class="project_logo_auto">
            <div class="project_logo_Project">Ecosystem Partner</div>
            <div>
                <ul class="project_logo_ul">
                    <li v-for="Url in imgList" :key="Url.url" :class="Url.is_boder ? 'project_logo_li flex-col':'project_logo_li project_logo_border flex-col'">
                        <a :href="Url.link" v-if="Url.link">
                            <img v-if="Url.url" class="img" :src="Url.url" alt="">
                        </a>
                        <div v-else>
                            <img v-if="Url.url" class="img" :src="Url.url" alt="">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="project_logo_Project">Ecosystem Investor</div>
            <div>
                <ul class="project_logo_ul">
                    <li v-for="proj in projList" :key="proj.url" :class="proj.is_boder ? 'project_logo_li flex-col':'project_logo_li project_logo_border flex-col'">
                        <a :href="proj.link" v-if="proj.link">
                            <img v-if="proj.url"  class="img" :src="proj.url" alt="" />
                        </a>
                        <div v-else>
                            <img v-if="proj.url"  class="img" :src="proj.url" alt="" />
                        </div>
                        
                    </li>
                </ul>
            </div>
            <div class="project_logo_Project" id="toEvent">Upcoming Event</div>
            <Events/>
        </div>
    </div>
</template>
<style scoped>
.project_logo_box {
    width: 100%;
}
.project_logo_auto {
    width: 344px;
    border-right: 2px solid #EFEFEF;
    border-left: 2px solid #EFEFEF;
    margin: 0 auto;
}
.project_logo_Project {
    width: 100%;
    height: 119px;
    border-bottom: 2px solid #EFEFEF;
    display: flex;
    align-items: center;
    padding-left: 14px;
    font-size: 32px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;

}
.project_logo_ul {
    width: 100%;
    overflow: hidden;

}
.project_logo_li {
    width: 170px;
    height: 71px;
    border-bottom: 2px solid #EFEFEF;
    float: left;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;

}
.project_logo_li:active {
    background-color: #F4f5fa;
}
.project_logo_ul {
    width: 100%;
}
.project_logo_border {
    border-right: 2px solid #EFEFEF;
}
.img {
    width: 100%;
    height: 41px;
}
</style>
<script>
import { ref } from "vue";
import Events from './events.vue'


export default {
  name: "nav",
  components: {
    Events
  },

  setup() {
    let imgList = ref([
        {
            url:require('../../assets/iphone/project_loge0.png'),
            link:'https://www.gaianet.ai/'
        },
        {
            url:require('../../assets/iphone/project_loge1.png'),
            link:'https://www.cybermiles.io',
            is_boder:'no',
        },{
            url:require('../../assets/iphone/project_loge2.png'),
            link:''
        },
        {
            url:require('../../assets/iphone/project_loge3.png'),
            link:'https://www.obridge.io/',
            is_boder:'no',
        },
        {
            url:require('../../assets/iphone/project_loge4.png'),
            link:'https://www.ladder.top/'
        },{
            url:require('../../assets/iphone/project_loge5.png'),
            link:'',
            is_boder:'no',
        },
        {
            url:require('../../assets/iphone/project_loge6.png'),
            link:''
        },
        {
            url:require('../../assets/iphone/project_loge7.png'),
            link:'https://www.filswan.com',
            is_boder:'no',
        },
        {
            url:require('../../assets/iphone/project_loge8.png'),
            link:'https://muadao.build/',
        },
        {
            url:require('../../assets/iphone/project_loge9.png'),
            link:'https://www.playerone.world/',
            is_boder:'no',
        },
        {
            url:require('../../assets/iphone/project_loge10.png'),
            link:'https://www.zkcross.org/'
        },
        {
            url:require('../../assets/iphone/project_loge11.png'),
            is_boder:'no',
            link:'https://www.catchervc.com/'
        },
        {
            url:require('../../assets/iphone/project_loge12.png'),
            link:'https://tokeninsight.com/'
        },
        {
            url:require('../../assets/iphone/project_loge13.png'),
            is_boder:'no',
            link:''
        },
        {
            url:require('../../assets/iphone/project_loge14.png'),
            link:'https://www.zksafe.pro/'
        },
        {
            url:require('../../assets/iphone/project_loge15.png'),
            is_boder:'no',
            link:'https://www.preangelfund.cn/'
        },
        {
            url:require('../../assets/iphone/project_loge17.png'),
            is_boder:'no',
            link:'https://allinweb3.io/'
        },
    ]);
    let projList = ref([
        {
            url:require('../../assets/iphone/logo_list1.png'),
            link:'https://sig-asiavc.com/'
        },{
            url:require('../../assets/iphone/logo_list2.png'),
            is_boder:'no',
            link:'https://www.incecap.com/'
        },
        {
            url:require('../../assets/iphone/logo_list3.png'),
            link:'https://www.bai-cap.com/'
        },
        {
            url:require('../../assets/iphone/logo_list4.png'),
            is_boder:'no',
            link:'https://www.bitdeer.com/'
        },{
            url:require('../../assets/iphone/logo_list5.png'),
            link:'https://www.kucoin.com/'
        },
        {
            url:require('../../assets/iphone/logo_list6.png'),
            is_boder:'no',
            link:'https://ngc.fund/'
        },
        {
            url:require('../../assets/iphone/logo_list7.png'),
            link:'https://www.sky9capital.com/'
        },{
            url:require('../../assets/iphone/logo_list8.png'),
            is_boder:'no',
            link:'https://www.brv.com/'
        },
        {
            url:require('../../assets/iphone/logo_list9.png'),
            link:'https://www.qimingvc.com/en'
        },
        {
            url:require('../../assets/iphone/logo_list10.png'),
            is_boder:'no',
            link:'https://www.betterversedao.org/'
        },
        {
            url:require('../../assets/iphone/logo_list11.png'),
            link:'https://blofin.io/'
        },{
            url:require('../../assets/iphone/logo_list12.png'),
            is_boder:'no',
            link:'https://labs.binance.com/'
        },

    ]);
    return {
        imgList,
        projList,
    };
  },

  props: {
    msg: String,
  },
};
</script>