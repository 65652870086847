<template>
    <div class="contentBottom_box" id="toEcosytem" >
        <div class="contentBottom_auto">
            <div class="contentBottom_Project">Ecosystem Partner</div>
            <div ref="Partner">
                <ul class="contentBottom_ul">
                    <li v-for="Url in imgList" :key="Url.url" :class="Url.is_boder ? 'contentBottom_li flex-col':'contentBottom_li contentBottom_border flex-col'">
                        <a :href="Url.link" class="img_hover" v-if="Url.link">
                            <img v-if="Url.url" :src="Url.url" alt="" :class="Ecosytem_Animate ? 'img fade-in-bottom-text':'img'">
                        </a>
                        <div v-else class="img_hover img_link">
                            <img v-if="Url.url" :src="Url.url" alt="" :class="Ecosytem_Animate ? 'img fade-in-bottom-text':'img'">
                        </div>
                    </li>
                </ul>
            </div>
            <div class="contentBottom_Project">Ecosystem Investor</div>
            <div>
                <ul class="contentBottom_ul">
                    <li v-for="con in conList" :key="con.url" :class="con.is_boder ? 'contentBottom_li width_click flex-col':'contentBottom_li width_click contentBottom_border flex-col'">
                        <a :href="con.link" class="img_hover" v-if="con.link">
                            <img class="img" :src="con.url" alt="" />
                        </a>
                        <div v-else class="img_hover img_link">
                            <img class="img" :src="con.url" alt="" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.contentBottom_box {
    width: 100%;
}
.contentBottom_auto {
    width: 1198PX;
    border-right: 2PX solid #EFEFEF;
    border-left: 2PX solid #EFEFEF;
    margin: 0 auto;
}
.contentBottom_Project {
    width: 100%;
    height: 125PX;
    border-bottom: 2PX solid #EFEFEF;
    display: flex;
    align-items: center;
    padding-left: 40PX;
    font-size: 44PX;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 700;
    color: #333333;

}
.contentBottom_ul {
    width: 100%;
    overflow: hidden;

}
.width_click {
    width:298PX !important;
}
.contentBottom_li {
    width: 298.5PX;
    height: 168PX;
    border-bottom: 2PX solid #EFEFEF;
    float: left;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;

}

.img_hover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}
.img_hover:hover {
    margin-top: -16px;
}
.contentBottom_ul {
    width: 100%;
}
.contentBottom_border {
    border-right: 2PX solid #EFEFEF;
}
.img {
    width: 100%;
    height: 96PX;
}
.img_link {
    cursor: pointer;
}
</style>
<script>
import { ref,onMounted,onBeforeUnmount } from "vue";
import { isElementNotInViewport } from "../../common/utils";


export default {
  name: "nav",
  components: {},

  setup() {
    let imgList = ref([
        {
            url:require('../../assets/iphone/project_loge0.png'),
            link:'https://www.gaianet.ai/'
        },
        {
            url:require('../../assets/content_img1.png'),
            link:'https://www.cybermiles.io'
        },{
            url:require('../../assets/content_img2.png'),
        },
        {
            url:require('../../assets/content_img3.png'),
            link:'https://www.obridge.io/',
            is_boder:'no',
        },
        {
            url:require('../../assets/content_img4.png'),
            link:'https://www.ladder.top/'
        },
        {
            url:require('../../assets/content_img5.png'),
        },
        {
            url:require('../../assets/content_img6.png'),
        },
        {
            url:require('../../assets/content_img7.png'),
            link:'https://www.filswan.com',
            is_boder:'no'
        },
        {
            url:require('../../assets/content_img8.png'),
            link:'https://muadao.build/',
        },
        {
            url:require('../../assets/content_img9.png'),
            link:'https://www.playerone.world/'
        },
        {
            url:require('../../assets/content_img10.png'),
            link:'https://www.zkcross.org/'
        },
        {
            url:require('../../assets/content_img11.png'),
            link:'https://www.catchervc.com/',
            is_boder:'no'

        },
        {
            url:require('../../assets/content_img12.png'),
            link:'https://tokeninsight.com/'

        },
        {
            url:require('../../assets/content_img13.png'),
            link:''
        },
        {
            url:require('../../assets/content_img14.png'),
            link:'https://www.zksafe.pro/'
        },
        {
            url:require('../../assets/content_img15.png'),
            link:'https://www.preangelfund.cn/',
            is_boder:'no',
        },
        {
            url:require('../../assets/content_img17.png'),
            link:'https://allinweb3.io/'
        },
        {
            name:0,
        },
        {
            name:0,
            is_boder:'no'
        },

    ]);
    let conList = ref([
        {
            url:require('../../assets/con13.png'),
            link:'https://sig-asiavc.com/'
        },{
            url:require('../../assets/con2.png'),
            link:'https://www.incecap.com/'
        },
        {
            url:require('../../assets/con3.png'),
            link:'https://www.bai-cap.com/'
        },
        {
            url:require('../../assets/con1.png'),
            is_boder:'no',
            link:'https://www.bitdeer.com/'
        },{
            url:require('../../assets/con4.png'),
            link:'https://www.kucoin.com/'
        },
        {
            url:require('../../assets/con5.png'),
            link:'https://ngc.fund/'
        },
        {
            url:require('../../assets/con11.png'),
            link:'https://www.sky9capital.com/'
        },{
            url:require('../../assets/con6.png'),
            is_boder:'no',
            link:'https://www.brv.com/'
        },
        {
            url:require('../../assets/con10.png'),
            link:'https://www.qimingvc.com/en'
        },
        {
            url:require('../../assets/con7.png'),
            link:'https://www.betterversedao.org/'
        },
        {
            url:require('../../assets/con12.png'),
            link:'https://blofin.io/'
        },{
            url:require('../../assets/con9.png'),
            is_boder:'no',
            link:'https://labs.binance.com/'
        },

    ]);
    let Ecosytem_Animate = ref(false);
    let Partner = ref(null)
    const scrollToTop = () => {
        !isElementNotInViewport(Partner.value) ? Ecosytem_Animate.value = true: Ecosytem_Animate.value = false;
    };
    onMounted(()=>{
        // 监听滚动事件
        window.addEventListener("scroll",scrollToTop);
    })
    onBeforeUnmount(()=>{
        //移除滚动事件
        window.removeEventListener('scroll',scrollToTop);
    })
    return {
        imgList,
        conList,
        Ecosytem_Animate,
        Partner
    };
  },

  props: {
    msg: String,
  },
};
</script>